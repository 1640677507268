@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --primary: #686ef0;
  --primary-active: #0e5aa5;
  --bg-auth: #f3f3fa;
  --white: #ffffff;
  --dark: #212129;
  --grey: #8e8e97;

  --inter: "Inter", sans-serif;
  --montserrat: "Montserrat", sans-serif;
}
*,
::after,
::before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.industry_analytics_bar_chart text {
  font-family: Manrope, sans-serif !important;
}
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  width: 190px;
  background-color: rgba(90, 90, 90, 0.9);
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  font-weight: 400;
  top: 0%; /* Position the tooltip above the text */
  left: 120%;
  margin-left: 0px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 10px;
  font-size: 10px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--dark);
  background-color: var(--white);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  overflow: auto;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
hr:not([size]) {
  height: 1px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  font-family: "Manrope", sans-serif;
}
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Manrope", sans-serif;
}
abbr[data-bs-original-title],
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 0.875em;
}
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
.title3 {
  padding-top: 100px;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  font: inherit;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.auth-multi-layout {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: right;
  justify-content: right;
  /* background-image: url("../img/login_background_latest.png"); */
  background-repeat: no-repeat;
  background-size: 90% auto;
  background-position: left -20px; /* Adjust the -50px value as needed */
}

.auth-box {
  width: 50%;
  background: #ffffff;
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0px 7px 29px 0px rgba(0, 0, 0, 0.2);
}

.landing-page-layout {
  position: relative;
  width: 100%;
  min-height: 100vh;
  /* display: flex;
  align-items: right;
  justify-content: right;
  background-image: url("../img/login_background_latest.png");
  background-repeat: no-repeat;
  background-size: 90% auto;
  background-position: left -20px ;  */
}

.landing-page-content {
  width: 100%;
  /* background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px 0px #333333; */
}

/* Define a class for the sidebar element */
.sidebar {
  direction: inherit;
  box-sizing: border-box !important;
  position: absolute;
  display: block;
  height: 100%;
  /* visibility: visible; */
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  padding: 0 !important;
  width: 15%;
  /* width: 15%;  */
  background-color: #f1f1f1;
  /* position: absolute; */
  height: 100%;
  /* left: 0; */
  /* top: 0;  */
  /* display: flex;

  /* justify-content: center;
  align-items: center; */
  flex-wrap: wrap;
}

/* Define a class for the main element */
.main {
  /* adjust as per your requirement */
  height: 100%;
}

.logo {
  /* Layout Properties */
  top: 58px;
  left: 38px;
  width: 178px;
  height: 43px;
  /* UI Properties */
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    30px/46px var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 30px/46px Poppins;
  letter-spacing: 0px;
  color: #000000cc;
  opacity: 0.9;
  margin-top: -940px;
}

.search-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 90%;
  margin-top: -10%;
}

.input-group {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 10px 0;
}

.typography {
  color: "#142C44CC";
  opacity: "1";
  /* variant:"h5"; */
  font-weight: "800";
  font-family: "Manrope, sans-serif";
  font-size: "20px";
}

.ant-table-tbody > tr > td {
  border: none;
}

.ant-table-tbody {
  background-color: #ffffff;
}

.ant-btn {
  padding: 0px 15px;
}

.input-group input {
  border-radius: 10px;
  width: 6.6vw;
  height: 3.1vh;
  border: 2px solid grey;
}

.checkbox-group {
  /* display: float; */
  /* flex-wrap: wrap; */
  width: 80%;
  color: #3e5367;
}

.title-loan-request {
  align-items: left;
  width: 80%;
  color: #3e5367;
}

.title-loan-request-filter {
  display: flex;
  align-items: left;
  width: 80%;
  color: #3e5367;
}

.customTable {
  width: 40% !important;
  border: 1px solid grey !important;
  border-radius: 10px !important;
}

.customTable2 {
  width: 60% !important;
  border: 1px solid grey !important;
  border-radius: 10px !important;
}

.select-group {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 10px 0;
}

.select-group select {
  border-radius: 10px;
}

input[type="text"] {
  padding: 5px;
  width: 45%;
}

input[type="checkbox"] {
  margin: 0 10px 10px 0;
}

.roundedTable .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 10px;
}

.roundedTable .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 10px;
}

.roundedTable .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 10px;
}

.roundedTable .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 10px;
}

select {
  padding: 5px;
  width: 100%;
}

/* Define a class for the header element */
header {
  background-color: #333;
  color: #fff;
  padding: 10px;
  min-height: 10vh;
}

.error-message {
  color: red;
}

.container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

/* .search-container {
  display: flex;
  justify-content: center;
} */

.search-input {
  width: 80%;
}

/* .search-input .ant-input-prefix {
  right: 10px;
  position: absolute;
} */

.icons {
  display: flex; /* Use flexbox to align icons */
  justify-content: right; /* Center icons vertically */
}

.icons i {
  font-size: 20px; /* Increase icon size */
  margin-right: 10px; /* Add margin between icons */
  color: #333; /* Change icon color */
  cursor: pointer; /* Add cursor pointer */
}

.icon-dashboard {
  font-size: 20px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer; /* Add cursor pointer */
  display: flex;
  justify-content: right;
}

.profile-picture {
  width: 45px;
  height: 40px;
  border-radius: 50%;
}

.profile-picture2 {
  width: 45px;
  height: 40px;
  border-radius: 50%;
  margin: 10px;
}

.profile-picture-lender {
  width: 65px;
  height: 60px;
  border-radius: 50%;
  padding-top: 10px;
  padding-left: 0px;
}

.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.contentAdmin {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  /* overflow-y: hidden; */
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9eb5ce;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d92a9;
}

::-webkit-scrollbar-button {
  height: 100px;
}

.menu {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* font: var(--unnamed-font-style-normal) normal 800 20px/27px Manrope; */
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  /* font: normal normal 800 20px/27px Manrope; */
  letter-spacing: 0px;
  color: #606264;
  opacity: 1;
}

.menu .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.menu .logo img {
  width: 50px;
  height: 50px;
}

.menu .logo p {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

/* Define a class for the footer element */
.footer {
  background-color: #333;
  color: #fff;
  padding: 1em;
  min-height: 10vh;
}

/* Media Queries for mobile */
@media (max-width: 600px) {
  .container {
    flex-wrap: nowrap;
  }
  .sidebar {
    width: 100%;
    order: 1;
  }
  .main {
    width: 100%;
    order: 2;
  }
}

.dashboard-layout {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-image: url("../img/register-background.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.verify-multi-layout {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: left;
  justify-content: left;
  background-image: url("../img/login_background_latest_2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-modal-content {
  background-color: rgb(250, 252, 255) !important;
}
.verify-box {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px 0px #333333;
  height: 100%;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .verify-box {
    width: 50%;
  }
}

.auth-header {
  padding: 1.4rem 1.5rem 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.auth-header-logo-img {
  width: 38px;
  margin-left: 0px;
  margin-right: 10px;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .auth-header-logo-img {
    width: 40px;
    margin-left: 30px;
    margin-right: 8px;
  }
}

.auth-header-mail-logo {
  width: 40px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.auth-header-otp-logo {
  width: 20px;
  margin-right: 8px;
  margin-bottom: 4px;
}
.forgot-img .auth-header-logo-img {
  width: 200px;
  margin-top: 50%;
}
.auth-header-title {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0;
  padding: 5.4rem 0rem 0.5rem 1.5rem;
  margin-top: 10px;
  color: #0e5aa5;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .auth-header-title {
    padding: 8rem 10.2rem 0.5rem 0rem;
  }
}

.auth-header-title-forgot-header {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;

  margin-top: 10px;
  color: #0e5aa5;

  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  display: grid;
  justify-content: center;
}

@media screen and (min-width: 1201px) {
  .auth-header-title-forgot-header {
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: -30px;
    margin-top: 10px;
  }
}

.auth-header-title-reset-header {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-left: 25px;
  margin-top: 10px;
  color: #0e5aa5;

  align-items: center;
  width: 100%;
  margin-bottom: 0px;
  display: grid;
  justify-content: center;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .auth-header-title-reset-header {
    margin-left: -58px;
  }
}

.auth-header-title-phone {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;

  margin-top: 100px;
  color: #0e5aa5;
}

@media screen and (min-width: 1201px) {
  .auth-header-title-phone {
    padding: 12.4rem 15.7rem 0.5rem 1rem;
    margin-top: 10px;
  }
}

.singpass-header-title {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  padding: 12.4rem 7.1rem 0.5rem 0.1rem;
  margin-top: 10px;
  color: #0e5aa5;
}

.auth-header-title-password {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;

  margin-top: 100px;
  color: #0e5aa5;
}

@media screen and (min-width: 1201px) {
  .auth-header-title-password {
    padding: 12.4rem 16.5rem 0.5rem 2.7rem;
    margin-top: 10px;
  }
}

.auth-header-register {
  font-family: "Poppins", sans-serif;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0;

  margin: 70px 0px 0px 0px;
  color: #0e5aa5;
}

@media screen and (min-width: 1201px) {
  .auth-header-register {
    padding: 12.4rem 14.8rem 0.5rem 0rem;
    margin-top: 10px;
  }
}

.auth-header-email {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0rem 3.3rem 0.5rem 1rem;
  margin-top: 8px;
  color: #0e5aa5;
}

.email-header-title {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  padding: 12.4rem 9.4rem 0.5rem 1.5rem;
  margin-top: 10px;
  color: #0e5aa5;
}

.otp-header-title {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;

  margin-top: 100px;
  color: #0e5aa5;
}

@media screen and (min-width: 1201px) {
  .otp-header-title {
    padding: 12.4rem 0rem 0.5rem 0rem;
    margin-top: 10px;
  }
}

.auth-header-title-verify {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;

  margin-top: 100px;
  color: #0e5aa5;
}

@media screen and (min-width: 1201px) {
  .auth-header-title-verify {
    padding: 12.4rem 15rem 0.5rem 1.5rem;
    margin-top: 10px;
  }
}

.auth-header-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  color: #6c757d;
  margin-right: 33px;
}

.auth-header-subtitle-forgot-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 10;
  color: #6c757d;
  /* margin-right: 33px; */
  /* margin-left: 195px; */

  align-items: center;
  width: 100%;
  display: grid;
  justify-content: center;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .input-control {
    width: 100%;
    font-size: 15px;
  }
}

.auth-header-subtitle-email {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  color: #6c757d;
  margin-right: 20px;
}

.auth-header-subtitle-register {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  color: #6c757d;
}

@media screen and (min-width: 1201px) {
  .auth-header-subtitle-register {
    margin-right: 350px;
    font-size: 15px;
  }
}

.stepper-register {
  margin-right: 364px;
}

.auth-header-subtitle-password {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  color: #6c757d;
}

@media screen and (min-width: 1201px) {
  .auth-header-subtitle-password {
    margin-right: 310px;
  }
}

.auth-header-subtitle-phone {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  color: #6c757d;
}

@media screen and (min-width: 1201px) {
  .auth-header-subtitle-phone {
    margin-right: 200px;
  }
}

.singpass-header-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  color: #6c757d;
  margin-right: 122px;
}

.auth-header-subtitle-verify {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  color: #6c757d;
  margin-left: 50px;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .auth-header-subtitle-verify {
    font-size: 15px;
    margin-right: -5%;
    margin-left: 0px;
  }
}

.email-header-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  margin-right: 80px;
  color: #6c757d;
}

.otp-header-subtitle {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 0;
  margin-right: 45px;
  color: #6c757d;
}

@media screen and (min-width: 1201px) {
  .otp-header-subtitle {
  }
}

.forgot-subtitle {
  margin-bottom: 10px;
}
.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-body {
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field {
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  display: grid;
  justify-content: center;
}
.input-label {
  display: flex;
  color: #6c757d;
}
.input-control {
  display: block;
  width: 300px;
  height: 35px;
  border-radius: 10px;
  border: 1px solid #8e8e97;
  padding: 0.25rem 0.15rem;
  padding-left: 10px;
  outline: none;
  transition: border 0.1s ease-in, box-shadow 0.15s ease-in;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .input-control {
    width: 520px; /* width override for larger screens */
  }
}

.input-control::placeholder {
  transition: transform 0.1s ease-in;
}
.input-control:focus::placeholder {
  transform: translateX(5px);
}
.input-control:focus,
.input-control:valid {
  border: 2px solid #0e5aa5;
}

.input-control-password {
  display: block;
  width: 520px;
  height: 35px;
  border-radius: 10px;
  border: 1px solid #8e8e97;
  padding: 0.25rem 0.15rem;
  outline: none;
  transition: border 0.1s ease-in, box-shadow 0.15s ease-in;
}
.input-control-password::placeholder {
  transition: transform 0.1s ease-in;
}
.input-control-password:focus::placeholder {
  transform: translateX(5px);
}
.input-control-password:focus,
.input-control-password:valid {
  border: 2px solid #0e5aa5;
}
.link-end {
  font-size: 15px;
  margin: 5px 0;
  text-decoration: none;
  color: #0e5aa5;
  align-items: right;
}
.link-end:hover {
  color: #3e45ce;
}

.otp-bx {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
  border-radius: 10px;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .otp-bx {
    margin-top: -4.5px;
  }
}

.otp-bx-input {
  width: 50px;
  font-size: 32px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  margin: 1px;
  font-weight: bold;
  color: black;
  outline: none;
  transition: all 0.1s;
}

.otp-bx-input:focus {
  border: 2px solid black;
  box-shadow: 0 0 2px 2px #fff;
}

.space {
  margin-right: 1rem !important;
}

.disabled {
  opacity: 0.5;
}

.btn-submit {
  display: inline-flex;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.35rem 1.24rem;
  border-radius: 10px;
  border: 1px solid #0e5aa5;
  background: #0e5aa5;
  color: #ffffff;
  width: 300px;
  height: 68px;
  padding-top: 20px;
  font-size: 20px;
}

@media screen and (min-width: 1201px) {
  .btn-submit {
  }
}

.btn-submit-forgot-password {
  display: inline-flex;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.35rem 1.24rem;
  border-radius: 10px;
  border: 1px solid #0e5aa5;
  background: #0e5aa5;
  color: #ffffff;
  width: 300px;
  padding-top: 20px;
  font-size: 20px;
}

@media screen and (min-width: 1201px) {
  .btn-submit-forgot-password {
    width: 520px;
    padding: 0.35rem 1.24rem;
  }
}

.disabled-button {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  display: inline-flex;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  text-decoration: none;
  padding: 0.35rem 1.24rem;
  border-radius: 10px;
  border: 1px solid;
  width: 300px;
  height: 68px;
  padding-top: 20px;
  font-size: 20px;
}

@media screen and (min-width: 1201px) {
  .disabled-button {
    width: 520px;
  }
}

.btn-submit-verify {
  display: inline-flex;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.45rem 1.24rem;
  border-radius: 10px;
  margin-left: -20px;
  border: 1px solid #0e5aa5;
  background: #0e5aa5;
  color: #ffffff;
  width: 250px;
  height: 41px;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .btn-submit-verify {
    margin-right: 279px;
    margin-left: 0px;
    width: 177px;
  }
}

.btn-submit-otp {
  display: inline-flex;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.45rem 1.24rem;
  border-radius: 10px;
  border: 1px solid #0e5aa5;
  background: #0e5aa5;
  color: #ffffff;
  width: 300px;
  height: 41px;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .btn-submit-otp {
    width: 177px;
  }
}

.btn-submit-email {
  display: inline-flex;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.45rem 1.24rem;
  margin-left: 70px;
  border-radius: 10px;
  border: 1px solid #0e5aa5;
  background: #0e5aa5;
  color: #ffffff;
  width: 200px;
  height: 41px;
}

.btn-submit-default-email {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.45rem 1.24rem;
  border-radius: 10px;
  border: 0px solid black;
  background: #ffffff;
  color: grey;
  width: 240px;
  height: 41px;
}

.btn-position {
  align-items: center;
  margin-top: 10px;
  display: block;
  text-align: center;
  margin-bottom: 15px;
  /* display: grid; */
  justify-content: center;
}

@media screen and (min-width: 1201px) {
  .btn-position {
    width: 100%;
    margin: 0 auto;
  }
}

.btn-position-email {
  align-items: center;
  margin: 0 auto;
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
  /* display: grid; */
  justify-content: center;
}

.btn-submit-default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.35rem 1.24rem;
  width: 300px;
  height: 68px;
  /* border: 1px solid #00000066; */
  border-radius: 10px;
  opacity: 1;
}

@media screen and (min-width: 1201px) {
  .btn-submit-default {
    width: 520px;
    margin-top: 10px;
  }
}

.btn-submit-google {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.35rem 1.24rem;
  border-radius: 10px;
  border: 1px solid black;
  background: #ffffff;
  color: black;
  width: 520px;
}

.btn-submit-singpass {
  /* display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.35rem 1.24rem;
  border-radius: 10px;
  border: 1px solid black;
  background: #ffffff;
  color: black;
  width: 560px; */
  /* border: 1px solid black; */
  border: 0px solid transparent;
  top: 700px;
  left: 1100px;
  /* width: 500px; */
  /* height: 108px; */
  opacity: 1;
  border-radius: 10px;
  background-color: white;
}
.btn-submit:hover {
  background: #3e45ce;
}
.flex-end {
  /* display: flex; */
  text-align: center;
  margin: 15px 0;
  /* justify-content: flex-end; */
}

.flex-end-phone {
  display: flex;
  align-items: center;
  text-align: right;
  font-size: 15px;
  color: #75757c;
  margin: 15px 30px;
  margin-right: 100px;
}

.flex-end-password {
  text-align: center;
  font-size: 15px;
  color: #75757c;
  margin: 15px 0;
  margin-right: 213px;
}
.text-center {
  text-align: center;
  font-size: 15px;
  color: #75757c;
  margin: 15px 0;
}

.text-center-register {
  text-align: center;
  font-size: 15px;
  color: #75757c;
  margin: 15px 0;
  margin-right: 285px;
}

.text-center-verify {
  text-align: center;
  font-size: 15px;
  color: #75757c;
  margin: 15px 0;
  margin-right: 285px;
}

.text-center-email {
  text-align: center;
  font-size: 15px;
  color: #75757c;
  margin: 15px 10px;
  padding: 0.35rem 13.34rem 0.5rem 1.2rem;
}

.text-center-otp {
  text-align: center;
  font-size: 15px;
  color: #75757c;
  margin: 15px 10px;
  padding: 0.35rem 5rem 0.5rem 5rem;
}

.link-text-center {
  font-size: 15px;
  /* margin: 5px 0; */
  margin-left: 340px;
  text-decoration: none;
  color: #0e5aa5;
  align-items: right;
}
.css-fl4apm-MuiPaper-root-MuiMobileStepper-root {
  margin-left: 0px !important;
}

.link-text-center-signup {
  font-size: 15px;
  margin: 5px 0;
  text-decoration: none;
  color: #0e5aa5;
  align-items: right;
}

.link-text-center-verify {
  font-size: 15px;
  margin: 5px 0;
  /* margin-left: 25px; */
  text-decoration: none;
  color: #0e5aa5;
  align-items: right;
}

.link-text-center-password {
  font-size: 15px;
  margin: 5px 0;
  /* margin-left: 25px; */
  text-decoration: none;
  color: #0e5aa5;
  align-items: right;
}

.link-text-center-otp {
  font-size: 15px;
  margin: 5px 0;
  /* margin-left: 25px; */
  text-decoration: none;
  color: #0e5aa5;
  align-items: right;
}

.link-text-center-signup {
  font-size: 15px;
  margin: 5px 0;
  /* margin-left: 25px; */
  text-decoration: none;
  color: #0e5aa5;
  align-items: right;
}

.link-text-email {
  text-decoration: none;
  color: #0e5aa5;
  margin: 15px 0;
}

.link-text-center:hover {
  color: #3e45ce;
}
.btn-back-to-login {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 0.35rem 1.24rem;
  border-radius: 10px;
  border: 1px solid transparent;
  background: transparent;
  color: #0e5aa5;
  margin-top: 5px;
  width: 100%;
}
.btn-back-to-login:hover {
  color: #3e45ce;
}
@media screen and (max-width: 1200px) {
  .auth-box {
    width: 100%;
    min-height: 90vh;
  }
}

.admin-layout {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.admin-content {
  width: 100%;
  padding-left: 270px;
  padding-top: 55px;
  transition: padding 0.5s;
}
.admin-wrapper {
  padding: 1.5rem 1.5rem;
}
.admin-header {
  position: fixed;
  width: 100%;
  padding-left: 270px;
  padding-right: 1.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1000;
  transition: padding 0.5s;
}
.admin-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 270px;
  height: 100vh;
  background: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: left 0.5s;
}
@media screen and (max-width: 768px) {
  .admin-content {
    padding-left: 0;
  }
  .admin-header {
    padding-left: 1.5rem;
  }
  .admin-sidebar {
    left: -100%;
  }
}
.admin-sidebar-header {
  position: relative;
  padding: 1.2rem 1.3rem;
}
.admin-sidebar-logo {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.admin-sidebar-logo .img-logo {
  width: 50px;
}
.admin-sidebar-logo .logo-name {
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  color: #0e5aa5;
}
.admin-sidebar-menu {
  position: relative;
  width: 100%;
  padding: 1rem 1.3rem;
}
.admin-sidebar-link {
  display: flex;
  text-decoration: none;
  user-select: none;
  width: 100%;
}
.admin-sidebar-link .admin-sidebar-item {
  position: relative;
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 10px;
  width: 100%;
  color: #707075;
  font-size: 1rem;
  padding: 0.45rem 0.89rem;
  column-gap: 5px;
}
.admin-sidebar-link .admin-sidebar-item:hover {
  background: #e1e1e6;
  color: #2c2c30;
}
.admin-sidebar-link .admin-sidebar-item.active {
  background: #0e5aa5;
  color: #ffffff;
  box-shadow: 0px 4px 15px #0e5aa5d7;
  transition: box-shadow 0.4s;
}
.admin-sidebar-link .admin-sidebar-item.active:hover {
  background: #484fcc;
  box-shadow: 0px 4px 10px #0e5aa5d7;
}

.admin-sidebar-item .admin-sidebar-icon {
  width: 25px;
  height: 25px;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}
.admin-sidebar-item .admin-sidebar-name {
  transition: transform 0.2s;
}
.admin-sidebar-item:hover .admin-sidebar-icon,
.admin-sidebar-item:hover .admin-sidebar-name {
  transform: translateX(5px);
}
.dropdown {
  position: relative;
  z-index: 100;
}
.dropdown-toggle {
  position: relative;
  padding: 0.2rem 0.35rem;
  border-radius: 6px;
  border: none;
  background: transparent;
}
.dropdown-toggle:hover {
  background: #efeff1;
}
.dropdown-toggle:active {
  background: #dbdbdf;
}
.dropdown-toggle.toggle-icon {
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4c4c52;
}
.dropdown-toggle-avatar {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  overflow: hidden;
}
.dropdown-toggle-avatar .dropdown-toggle-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.dropdown-menu {
  position: absolute;
  display: none;
  list-style: none;
  top: 100%;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 7px 15px rgba(100, 100, 111, 0.18);
  border-radius: 8px;
  padding: 0.5rem 0.5rem;
  min-width: 200px;
  max-width: 350px;
  transform: translateY(10px);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0;
  z-index: 1000;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-menu.dropdown-menu-notifications {
  min-width: 300px;
}
.dropdown-link {
  width: 100%;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background: transparent;
  border: none;
  overflow: hidden;
  column-gap: 5px;
  color: #4d4d50;
  padding: 0.25rem 1rem;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  transition: background 0.2s;
}
.dropdown-link:hover {
  background: #e7e7eb;
  color: #0e5aa5;
}
.dropdown-link-icon {
  font-size: 1.4rem;
}
.dropdown-link-notification {
  padding: 0.35rem 1rem;
  column-gap: 10px;
}
.dropdown-link-notification .notification-icon {
  min-width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #484fcc20;
  color: #484fcc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
.dropdown-link-notification .notification-details {
  display: block;
}
.notification-details .notification-title {
  font-size: 15px;
  font-weight: 500;
  color: #212529;
  margin: 0;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notification-details .notification-description {
  font-size: 13px;
  margin: 0;
  color: #8e8e97;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-widget {
  position: relative;
  width: 100%;
  padding: 1rem 1.2rem;
  background: #ffffff;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.card-widget.widget-user {
  background: #858ae6;
  border: none;
}
.widget-user-decore {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.widget-decore-left {
  position: absolute;
  top: 0;
  left: 0;
}
.widget-decore-right {
  position: absolute;
  top: 0;
  right: 0;
}
[class*="widget-decore-"] img {
  width: 200px;
}
.card-widget-header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.8rem;
}
.widget-icon {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  background: #3e45ce25;
  color: #3e45ce;
}
.widget-user .widget-icon {
  width: 60px;
  height: 60px;
  font-size: 1.8rem;
  color: #ffffff;
}
.widget-user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 1rem;
}
.widget-user-title {
  font-size: 23px;
  font: 2500px;
  margin-bottom: 4px;
  color: #ffffff;
}
.widget-user-description {
  font-size: 13px;
  font-weight: 500;
  color: #e4e4ec;
  margin: 0;
}
.widget-flex {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.widget-count {
  font-size: 19px;
  margin: 0;
  font-weight: 500;
  color: #212529;
}
.widget-name {
  font-size: 14px;
  margin: 0;
  color: #838385;
}
.breadcrumb {
  display: flex;
  align-items: center;
  padding: 0 0;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  list-style: none;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #75757c;
  content: var(--sc-divider, "/");
}
.breadcrumb-item.active {
  color: #6c757d;
}
.breadcrumb-item .breadcrumb-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 4px;
  color: #0e5aa5;
}
.breadcrumb-item .breadcrumb-link .breadcrumb-link-icon {
  width: 15px;
  height: 15px;
  display: grid;
  place-content: center;
  font-size: 1.15rem;
}
.grid-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 250px));
  gap: 10px;
}
@media screen and (max-width: 480px) {
  .grid-box {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}
.card-product {
  position: relative;
  background: #ffffff;
  border: 1px solid #e7e7eb;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;
  padding: 0.85rem 1rem;
}
.card-product-header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-brand {
  margin-bottom: 3px;
  color: #2c2c30;
  font-size: 15px;
  font-weight: 600;
}
.product-categories {
  margin: 0;
  font-size: 12px;
  color: #838397;
}
.card-product-img {
  width: 100%;
  position: relative;
  border-radius: 8px;
  height: 120px;
  background: #ffffff;
  overflow: hidden;
  margin: 8px 0;
}
.card-product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.product-name {
  font-size: 15px;
  font-weight: 600;
  color: #2c2c30;
  margin-bottom: 5px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.flex-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-price,
.product-stock {
  font-size: 14px;
  font-weight: 500;
  color: #838385;
  margin: 0;
}
.product-rate {
  display: inline-flex;
  align-items: center;
  column-gap: 3px;
  margin-top: 4px;
}
.icon-rate {
  font-size: 1rem;
  color: #f1a90b;
}
.rate {
  font-size: 0.8rem;
  font-weight: 500;
  color: #8e8e97;
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.dialog.show {
  display: flex;
}
.dialog::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.dialog.show::before {
  animation: fade_show 0.5s forwards;
}
.dialog-content {
  position: relative;
  max-width: 450px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
  border: 1px solid #e7e7eb;
  transform: translateY(-30px);
  opacity: 0;
  visibility: hidden;
}
.dialog.show .dialog-content {
  animation: dialog_show 0.5s forwards;
}
.dialog-header {
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #212129;
}
.btn-dialog-close {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  text-decoration: none;
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: transparent;
  border: none;
  color: #474752;
  cursor: pointer;
}
.btn-dialog-close i {
  position: relative;
  top: 1px;
}
.btn-dialog-close:hover {
  background: #efeff1;
}
.btn-dialog-close:active {
  background: #dbdbdf;
}
.dialog-body {
  padding: 0.5rem 1.2rem;
}
.dialog-img-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-img-body img {
  width: 250px;
}
.dialog-message {
  font-size: 17px;
  margin: 0;
  font-weight: 600;
  color: #4c4c52;
  text-align: center;
}
.dialog-footer {
  padding: 0.8rem 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.checkbox-login {
  margin-right: 16rem;
}
.btn-dialog {
  position: relative;
  display: inline-block;
  padding: 0.4rem 1.2rem;
  border: none;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  font-size: 1rem;
  border-radius: 8px;
  transition: background 0.3s, color 0.1s;
}
.btn-dialog-cancel {
  background: #a4a5af;
  color: #ffffff;
}
.btn-dialog-cancel:hover {
  background: #5b5c64;
  color: #ffffff;
}
.btn-dialog-delete {
  background: #f8ecec;
  color: #ca0a0a;
}
.btn-dialog-delete:hover {
  background: #ee1a1a;
  color: #ffffff;
}

@keyframes fade_show {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes dialog_show {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.recharts-sector {
  filter: drop-shadow(0px 0px 0px grey);
}

.buttonExpress,
.buttonBorrower {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  border: none;
  border-radius: 1000px;
  width: 75%; /* default width */
  height: 40px;
  margin-top: 5px;
}

.buttonExpress {
  background: #fed065;
  margin-bottom: 15px;
}

.buttonBorrower {
  background: linear-gradient(
    90deg,
    rgba(138, 156, 197, 0.8) 0%,
    rgba(21, 87, 169, 1) 100%
  );
  margin-bottom: 7px;
}

.buttonExpress:hover {
  background: rgba(254, 208, 101, 0.7);
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .buttonExpress,
  .buttonBorrower {
    width: 8vw; /* width override for larger screens */
  }
}

.buttonBorrower:hover {
  background: linear-gradient(
    90deg,
    rgba(138, 156, 197, 0.7) 0%,
    rgba(21, 87, 169, 0.7) 100%
  );
}

.buttonExpressPendingAcceptance {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  background: #fed065;
  border: none;
  border-radius: 1000px;
  width: 90%;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.buttonExpressPendingAcceptance:hover {
  background: rgba(254, 208, 101, 0.7);
}

.buttonBorrowerPendingAcceptance {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(138, 156, 197, 0.8) 0%,
    rgba(21, 87, 169, 1) 100%
  );
  border: none;
  border-radius: 1000px;
  width: 90%;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 7px;
}

.buttonBorrowerPendingAcceptance:hover {
  background: linear-gradient(
    90deg,
    rgba(138, 156, 197, 0.7) 0%,
    rgba(21, 87, 169, 0.7) 100%
  );
}

.buttonNotInterested {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: grey;
  text-align: center;
  background: transparent;
  border: 1px solid grey;
  border-radius: 1000px;
  width: 8vw;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.buttonNotInterested:hover {
  color: white !important;
  background: linear-gradient(
    90deg,
    rgba(138, 156, 197, 0.8) 0%,
    rgba(21, 87, 169, 1) 100%
  );
  border: none !important;
}

.buttonExpressList {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 7px;
  color: #ffffff;
  text-align: center;
  background: #fed065;
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 16px;
}

.buttonExpressList:hover {
  background: rgba(254, 208, 101, 0.7);
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .buttonExpressList .buttonBorrowerList {
    width: 8vw; /* width override for larger screens */
    margin: 20px 20px 00px 0px;
    font-size: 12px;
    height: 32px;
  }
}

.buttonBorrowerList {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 7px;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(138, 156, 197, 0.8) 0%,
    rgba(21, 87, 169, 1) 100%
  );
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 16px;
}

.buttonBorrowerList:hover {
  background: linear-gradient(
    90deg,
    rgba(138, 156, 197, 0.7) 0%,
    rgba(21, 87, 169, 0.7) 100%
  );
}

.buttonNotInterestedList {
  font-family: Poppins, sans-serif;
  font-weight: 800;
  font-size: 7px;
  color: grey;
  text-align: center;
  background: transparent;
  border: 1px solid grey;
  border-radius: 20px;
}

/* Media query for screen widths greater than 1200px */
@media screen and (min-width: 1201px) {
  .buttonNotInterestedList {
    width: 80%; /* width override for larger screens */
    font-size: 12px;
    margin: 20px 20px 0px -12px;
  }
}

.buttonNotInterestedList:hover {
  color: white !important;
  background: linear-gradient(
    90deg,
    rgba(138, 156, 197, 0.8) 0%,
    rgba(21, 87, 169, 1) 100%
  );
  border: none !important;
}

.full-screen-modal .ant-modal {
  top: 0;
  padding: 0 !important;
  margin: 0 !important;
}

.full-screen-modal .ant-modal-content {
  height: 100vh;
  width: 100vw;
  padding: 0;
  overflow-y: hidden;
}

.full-screen-modal .ant-modal-wrap {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.full-screen-modal-1 .ant-modal {
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  padding: 0 !important;
  margin: 0px !important;
}

.full-screen-modal-1 .ant-modal-content {
  height: 60vh;
  width: 90vw;
  padding: 0;
  overflow-y: hidden;
}

.full-screen-modal-1 .ant-modal-wrap {
  display: flex;
  align-items: stretch;
  justify-content: center;
}

/* .scrollContainer {
  overflow: auto !important;
    
  } */

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  z-index: 2001 !important;
  width: 20px;
  top: 12px;
  left: 12px;
}

/* @media (max-width: 800px) {
  .bm-burger-button {
    position: absolute;
    z-index: 2001 !important;
    width: 20px;
    top: 48px;
  }
} */
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: black;
  color: black;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
}

/* General sidebar styles */
.bm-menu {
  background: rgba(256, 256, 256, 0.4);
  backdrop-filter: blur(10px) !important;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: white;
}

/* Individual item */
/* .bm-item {
  display: inline-block;
} */

/* Styling of overlay */
.bm-overlay {
  background: transparent !important;
}

.hamburger-react {
  color: black !important;
}

.MuiSvgIcon-root {
  color: grey;
}

.ant-collapse {
  margin-left: "50px !important;";
}

.odd_icon_setting {
  margin-left: 5px;
}

.colored_white_icon {
  color: white !important;
}

.input_base input::placeholder {
  font-size: 12px;
}

.notification_item {
  background: none;
  padding: 10px;
}

.notification_item:hover {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 10px;
}

.ant-popover-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 5%;
  margin-top: 5px;
}

.recharts-sector {
  stroke: transparent;
}

.apply_now_button {
  scale: 1;
  transition: 0.3s all ease-in-out;
}

.apply_now_button:hover {
  color: white !important;
  background: #115ca6 !important;
  scale: 1.08;
  transition: 0.3s all ease-in-out;
}

.all_button {
  scale: 1;
  transition: 0.3s all ease-in-out;
}

.all_button:hover {
  scale: 1.08;
  transition: 0.3s all ease-in-out;
}

.ant-collapse-header-text {
  margin-inline-end: initial !important;
  flex: initial !important;
}

.ant-collapse-header {
  display: flex;
  justify-content: center;
}

.ant-table-cell {
  font-size: 11px;
  font-weight: 800 !important;
}

.analytics_button {
  color: "#0E5AA5" !important;
  background-color: transparent !important;
}

.analytics_button:hover {
  color: #fff !important;
  background-color: #0e5aa5 !important;
  transition: 0.3s all ease-in-out;
}

.analytics_button:hover .analytics_button_icon {
  color: #fff !important;
  background-color: transparent !important;
  transition: 0.3s all ease-in-out;
}

.recharts-tooltip-label {
  display: none !important;
}

.recharts-cartesian-axis-line {
  display: none !important;
}
.recharts-cartesian-grid-horizontal line {
  stroke-dasharray: 0 !important;
}

.recharts-cartesian-grid-vertical line {
  display: none;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-text tspan {
  color: rgba(0, 0, 0, 0.4);
}
.client_bar_chart text {
  opacity: 0 !important;
}
.client_bar_chart line {
  opacity: 0 !important;
}

.client_bar_chart rect {
  width: 20px !important;
  margin-left: -25px !important;
  rx: 3px !important;
  ry: 3px !important;
}

.industry_analytics_bar_chart rect {
  width: 50px !important;
  rx: 5px !important;
  ry: 5px !important;
}

.industry_analytics_bar_chart line {
  opacity: 0.1 !important;
}

.industry_analytics_bar_chart text {
  font-family: Manrope, sans-serif !important;
  fill: rgba(0, 0, 0, 0.5) !important;
  transform: translate(17px, 10px) !important;
}

.ant-upload {
  width: 100% !important;
}

.rotating_hourglass {
  animation: rotateAndHold 2s linear infinite;
}

@keyframes rotateAndHold {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.blink {
  animation: blinking 1s linear infinite;
}

@keyframes blinking {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.ant-tabs-tab-btn .blinking {
  background: rgb(14, 90, 165) !important;
  padding: 5px 20px !important;
  color: white !important;
  border-radius: 6px !important;
  animation: blinkings 1.5s linear infinite;
}

.blink {
  animation: blinking 1s linear infinite;
}

@keyframes blinkings {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes blinking {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

/* @keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.blinking {
  animation: blink 1s infinite;
} */

.db_user_row {
  background: transparent;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.db_user_row:hover {
  background: rgba(0, 0, 0, 0.05);
  transition: 0.3s all ease-in-out;
}

.ant-table-row.expired-row,
.ant-table-row.expired-row:hover {
  background-color: grey !important;
  opacity: 0.5;
}

.error-border {
  border: 2px solid red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.loading-overlay {
  position: fixed; /* Fixed position to cover the whole viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
  z-index: 9999; /* A high value to ensure it's on top of everything else, including modals */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
}

@keyframes move-right {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

.move-right {
  animation: move-right 50s linear infinite;
}

.quill_editor_content img {
  object-fit: contain;
  max-height: 60vh;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  background-color: white !important;
  border-radius: 9px !important;
  border: none !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.ql-editor img {
  object-fit: contain;
  max-height: 60vh;
}

.mui_button {
  cursor: pointer;
  opacity: 1;
  transition: 0.3s all ease-in-out;
}

.mui_button:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: 0.3s all ease-in-out;
}

.sc-eDvSVe {
  border-radius: 10px !important;
  color: black !important;
  padding-top: 35px !important;
}

.row_click {
  opacity: 1;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.row_click:hover {
  opacity: 0.2;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}

.icon-normal {
  color: #142c44;
  font-size: 28px !important;
}
.icon-selected {
  color: white;
  font-size: 28px !important;
}

.document-creation-input {
  width: 350px;
  border-radius: 8px;
  padding: 6px 25px;
  background-color: rgba(256, 256, 256, 0.4);
  border: 1px solid #142c446a;
  outline: none;
  margin-top: 4px;
}

.document-creation-input-label {
  font-size: 13px;
  color: #142c446a;
}

.document-builder-button {
  padding: 10px 25px;
  color: white;
  background-color: rgb(14, 90, 165);
  border-radius: 8px;
  cursor: pointer;
}

.dbuilder-header-map {
  cursor: pointer;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dbuilder-header-map-image {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.document-hover:hover {
  background: rgb(14, 90, 165) !important;
  color: white !important;
  border-radius: 6px !important;
}

.document-cursor:hover {
  background: rgba(256, 256, 256, 0.4) !important;
}

.marquee {
  width: 100%; /* Full width container */
  overflow: hidden; /* Hide the text that goes outside the container */
  white-space: nowrap; /* Prevent the text from wrapping */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.marquee span {
  display: inline-block;
  padding-left: 100%; /* Ensure text starts outside the container */
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%); /* Text starts outside to the right */
  }
  100% {
    transform: translateX(-100%); /* Text ends outside to the left */
  }
}

.hoverbuttonStart:hover {
  width: 120px !important;
  height: 120px !important;
  outline: 2px solid rgba(242, 193, 32) !important;
}

.hoverbuttonStart:hover .hoverbuttonStartchild {
  background-color: rgb(242, 193, 32) !important;
}

.border_change_hover:hover {
  border: 1px solid #1557a98a !important;
  cursor: pointer;
}

@keyframes blinkOutline {
  0% {
    outline: 4px solid rgb(14, 90, 165);
  }
  50% {
    outline: 4px solid transparent;
  }
  100% {
    outline: 4px solid rgb(14, 90, 165);
  }
}

.blinking-outline {
  outline: 4px solid rgb(14, 90, 165);
  padding: 3px;
  border-radius: 12px;
  animation: blinkOutline 2s infinite;
}

.styledSelect select option {
  background: rgba(256, 256, 256, 0.1) !important;
  color: white !important;
}

@keyframes blinkdiv {
  0% {
    background-color: rgb(14, 90, 165);
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: rgb(14, 90, 165);
  }
}

.blink_property_div {
  animation: blinkdiv 2s linear infinite;
}

@keyframes blinkicon {
  0% {
    color: rgba(14, 90, 165, 0.4);
  }
  50% {
    color: rgb(14, 90, 165);
  }
  100% {
    color: rgba(14, 90, 165, 0.4);
  }
}
.blink_property_icon {
  animation: blinkicon 2s linear infinite;
}


@keyframes blinktext {
  0% {
    color: rgba(20, 44, 68, 0.8);
  }
  50% {
    color: rgba(20, 44, 68, 0.4);
  }
  100% {
    color: rgba(20, 44, 68, 0.8);
  }
}
.blink_property_text {
  animation: blinktext 2s linear infinite;
}


@keyframes pulseBackground {
  0% {
    background: rgba(14, 90, 165, 0.4);
    transform: scale(1);
  }
  50% {
    background: rgb(14, 90, 165);
    transform: scale(1.05);
  }
  100% {
    background: rgba(14, 90, 165, 0.4);
    transform: scale(1);
  }
}

.special_button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  animation: pulseBackground 1.5s infinite alternate ease-in-out;
}


.hover_underline {
  text-decoration: none;
}

.hover_underline:hover {
  text-decoration: underline;
}