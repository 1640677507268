.menu_button:hover {
  scale: 1.05;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.menu_button {
  scale: 1;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.pop_up_image img {
  width: 100%;
}
